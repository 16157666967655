//-------------------------------------------------------------------
// 構成
.sec {
  padding: 20px 8px;
}
.sec_title {
  padding: 8px 0 8px 16px;
  border-left: solid 8px #00BCD4;
  border-bottom: solid 1px #00BCD4;
}


//-------------------------------------------------------------------
// ヘッダー
.header > a {
  color: #2a2a2a;
}
.header_title {
  display: inline-block;
  padding-top: 4px;
  padding-bottom: 4px;
}
.header_title-icon {
  display: inline-block;
  position: relative;
  top: 4px;
  width: 40px;
  height: 32px;
  background-image: url("../images/common/feliXani_logo_2018.png");
  background-size: cover;
}
.header_title-caption {
  font-weight: bold;
  font-family: NotoSansJP_R;
}
.header_title-caption-regular {
  padding-left: 16px;
  font-size: 32px;
}
.header_title-caption-small {
  font-size: 20px;
}

//-------------------------------------------------------------------
// メニュー
.menu {
  display: flex;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 0;
}

.menu_item {
  // border: solid 1px #f00;
  padding: 0 8px;
  height: 48px;
}
.menu_item-link {
  line-height: 48px;
}
.menu_item-link--caption {
  font-size: 12px;
  font-weight: bold;
  color: #383838;
}

//-------------------------------------------------------------------
// 企業トップ画像周り
.companytop {
  position: relative;
  max-height: 400px;
  overflow-y: hidden;
  vertical-align: middle;
}
.companytop_image {
  width: 100%;
}
.companytop_messageBox {
  position: absolute;
  bottom: 16px;
  right: 0;
  width: 80%;
  @media (max-width : 768px) {
    height: 72px;
  }
  @media (min-width : 769px) {
    height: 56px;
  }

  background-color: rgba(0, 0, 0, 0.6);
}
.companytop_messageBox-text {
  padding: 8px 0;
  margin: 0;
  color: #fff;
  text-align: center;

  @media (max-width : 768px) {
    font-size: 20px;
  }
  @media (min-width : 769px) {
    font-size: 24px;
  }

}



//-------------------------------------------------------------------
// 事業
.row_index-2 {
  padding-top: 32px;
  border-top: solid 1px #ccc;
}
// 事業単位
.service {
  display: flex;
  @media (max-width: 768px) {
    // "column": フレックスコンテナの主軸の方向と起点・終点の位置は、ブロック要素と同様の縦方向（デフォルトは横方向の "row"）
    flex-direction: column;
  }
}
.service-japandailyphoto {
  @media (max-width : 768px) {
    margin-top: 30px;
    padding-top: 20px;
    border-top: solid 1px #ccc;
  }
}
.service-japandailyphoto > .service_item-body > .service_item-body--title {
  letter-spacing: 0;
}
.service-takeoutmap-glideapp {
  margin-top: 40px;
  padding-top: 20px;
}
.service_item-descGlide {
  margin: 20px 0;
}
.service_item-body--glideapp {
  margin-left: 20px;
}
.service_link {
  color: #2a2a2a;
}

@media (max-width : 768px) {
  .service_item-thumb { order: 2;}
  .service_item-body { order: 1;}
  .service_item-desc { order: 3;}
}
@media (min-width : 769px) {
  .service_item-thumb { order: 1;}
  .service_item-body { order: 2;}
  .service_item-desc { order: 3;}
}

.service_item-body--title {
  font-family: "Helvetica Neue";
  font-weight: bold;
}
.service_item-thumb {
  width: 40%;
  @media (max-width : 768px) {
    width: 100%;
    text-align: center;
  }

}
.service_item-thumb--img {
  @media (max-width : 768px) {
    max-width: 290px;
  }
}
.service_item-thumb--img-glideapp {
  @media (max-width : 768px) {
    padding: 16px;
    max-width: 768px;
  }
}

.service_item-body {
  width: 60%;
  padding: 8px;
  @media (max-width : 768px) {
    width: 100%;
  }
}
.service_item-body--glideapp {
  @media (max-width : 768px) {
    padding: 0;
    margin-left: 8px;
  }
}
.service_item-body--link {
  text-decoration: underline;
  display: inline-block;
  padding: 4px 0 16px;
}
.service_item-desc {
  padding-left: 8px;
  padding-right: 8px;
}


//-------------------------------------------------------------------
// 会社情報
.company {}
.company_info {
  display: flex;
  padding: 16px 0;
  border-bottom: solid 1px #e8e8e8;
  @media (max-width: 768px) {
    // "column": フレックスコンテナの主軸の方向と起点・終点の位置は、ブロック要素と同様の縦方向（デフォルトは横方向の "row"）
    flex-direction: column;
    border-bottom: none;
  }
}
.company_info-head {
  width: 30%;
  @media (max-width: 768px) {
    width: 100%;
    padding: 4px 8px;
    background: #e8e8e8;
  }
}
.company_info-body {
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
    padding: 8px 24px 0;
    background: #fff;
  }
}

//-------------------------------------------------------------------
// 問い合わせ
.contact {
  margin: 40px 0 60px;
}


@media (max-width : 768px) {
}


@media (min-width : 769px) {
}