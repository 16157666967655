.is-hide {
  display: none;
}


.u-mgn-t4  { margin-top: 4px; }
.u-mgn-t8  { margin-top: 8px; }
.u-mgn-t12 { margin-top: 12px; }
.u-mgn-t16 { margin-top: 16px; }
.u-mgn-t20 { margin-top: 20px; }
.u-mgn-t24 { margin-top: 24px; }

.u-mgn-b4  { margin-bottom: 4px; }
.u-mgn-b8  { margin-bottom: 8px; }
.u-mgn-b12 { margin-bottom: 12px; }
.u-mgn-b16 { margin-bottom: 16px; }
.u-mgn-b20 { margin-bottom: 20px; }
.u-mgn-b24 { margin-bottom: 24px; }

.u-no-mgn {
  margin: 0;
}

.u-no-pad {
  margin: 0;
}

.u-algn-center-x {
  position: relative;
  left: 50% !important;
  transform: translateX(-50%);
}
.u-algn-center-y {
  position: relative;
  top: 50% !important;
  transform: translateY(-50%);
}
.u-algn-center {
  position: relative;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}

.fnt-bold { font-weight: bold; }
.fntsz-10 { font-size: 10px; }
.fntsz-12 { font-size: 12px; }
.fntsz-14 { font-size: 14px; }
.fntsz-15 { font-size: 15px; }
.fntsz-16 { font-size: 16px; }
.fntsz-17 { font-size: 17px; }
.fntsz-18 { font-size: 18px; }
.fntsz-20 { font-size: 20px; }
.fntsz-22 { font-size: 22px; }
.fntsz-24 { font-size: 24px; }
.fntsz-26 { font-size: 26px; }

// -------------------------------------------------------------------------------------------------
//  SP
@media (max-width : 768px) {
  .for-pc {
    display: none !important;
  }
}

// -------------------------------------------------------------------------------------------------
//  PC
// -------------------------------------------------------------------------------------------------
@media (min-width : 769px) {
  .for-sp {
    display: none !important;
  }
}