@import "./_color";
@import "./_font";
@import "./_uty";

@media (max-width : 768px) {
}

@media (min-width : 769px) {
}

html, body {
  height: 100%;
  padding: 0;
  min-width: 320px;
}

body {
  overflow-x: hidden;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.65px;
}

header {
  background: #fff;
  color:  #2e2e2e;
  padding: 0 32px;
}

nav {
  height: 48px;
  background: $col_nav_bg;
  transition: .3s;

  &.is-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
  }
  &.is-hide {
    transform: translateY(-100%);
  }
  @media (max-width : 768px) {
    text-align: center;
  }
}

footer {
  display: block;
  margin: 0 auto;
  padding: 20px 0 30px;
  background: #3c3c3c;
  color: #fff;
  .footer_info {
    text-align: center;
    .footer_info-copyright--icon {
      font-size: 32px;
    }
    .footer_info-copyright--company {
      position: relative;
      top: -7px;
      padding: 0 0 0 8px;
      font-size: 14px;
    }
  }
}

main {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}

p {
  margin-bottom: 0.5rem;
}

ul, ol {
  padding: 0;
  list-style: none;
}